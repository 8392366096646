// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
console.time('hello');

require("@rails/ujs").start();
// require("@hotwired/turbo-rails");
// import "@hotwired/turbo-rails"
require("@rails/activestorage").start();
require("channels");
import "@hotwired/turbo-rails";
Turbo.session.drive = false
import "../custom/companion";

import "../src/jquery"
import "../custom/analytics"
import "../custom/cookie";
import "materialize-css/dist/js/materialize.min";

import "../custom/float_to_words";



import "vanilla-nested";



require("openweather/openweather.min.js");
require('default-passive-events');


require("../plugins/index");

require("../perfect-scrollbar/jquery.scrollbar.min.js");

// de aici incepe

var i = 0;




require("gsap/gsap");





document.addEventListener("turbo:load", function() {
    const importTable = document.querySelector('#import-table');
    if (importTable) {
      const tbody = importTable.querySelector('tbody');
  
      // Funcție pentru adăugarea unui rând nou
      function addNewRow() {
        const newRow = document.createElement('tr');
        for(let i = 0; i < 7; i++) {
          const td = document.createElement('td');
          td.contentEditable = true;
          td.className = 'paste-cell';
          // Adăugăm event listener pentru paste individual
          td.addEventListener('paste', function(e) {
            e.preventDefault();
            const text = e.clipboardData.getData('text');
            td.textContent = text.trim();
          });
          newRow.appendChild(td);
        }
        tbody.appendChild(newRow);
        return newRow;
      }
  
      // Buton pentru adăugare rând nou
      const addRowButton = document.querySelector('#add-row');
      addRowButton.addEventListener('click', addNewRow);
  
      // Gestionare paste pe header pentru coloane întregi
      importTable.querySelectorAll('th').forEach((th, columnIndex) => {
        th.addEventListener('click', function() {
          importTable.querySelectorAll('th').forEach(header => 
            header.classList.remove('selected-column'));
          th.classList.add('selected-column');
          
          document.addEventListener('paste', function pasteHandler(e) {
            e.preventDefault();
            const pastedText = e.clipboardData.getData('text');
            const rows = pastedText.split('\n').filter(row => row.trim());
            
            // Asigură-te că avem suficiente rânduri
            while(tbody.children.length < rows.length) {
              addNewRow();
            }
            
            // Completează celulele cu datele lipite
            rows.forEach((value, index) => {
              const cell = tbody.children[index].children[columnIndex];
              if (cell) {
                cell.textContent = value.trim();
              }
            });
            
            document.removeEventListener('paste', pasteHandler);
            th.classList.remove('selected-column');
          }, { once: true });
        });
      });
  
      // Buton de ștergere
      const clearButton = document.querySelector('#clear-button');
      clearButton.addEventListener('click', function() {
        tbody.innerHTML = '';
        // Adaugă înapoi un rând gol
        addNewRow();
      });
  

    // Buton de salvare
const submitButton = document.querySelector('#submit-button');
submitButton.addEventListener('click', function() {
    const data = [];
    const headers = ['name', 'obs', 'meter', 'new_index', 'old_index', 'consumption', 'tax'];
    const blockId = document.querySelector('[data-block-id]').dataset.blockId;
    const meterId = document.querySelector('[data-meter-id]').dataset.meterId; // Adaugă asta în view
    
    tbody.querySelectorAll('tr').forEach(row => {
        const rowData = {};
        Array.from(row.children).forEach((cell, index) => {
            rowData[headers[index]] = cell.textContent.trim();
        });
        if (Object.values(rowData).some(v => v)) {
            data.push(rowData);
        }
    });

    if (data.length === 0) {
        M.toast({html: 'Nu există date de salvat!'});
        return;
    }

    fetch(`/dashboard/blocks/${blockId}/meters/import_table_data`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({ 
            meters: data,
            meter_id: meterId
        })
    })
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            M.toast({html: data.message || 'Datele au fost salvate cu succes!'});
            clearButton.click();
        } else {
            M.toast({html: data.message || 'A apărut o eroare la salvarea datelor.'});
        }
    })
    .catch(error => {
        M.toast({html: 'A apărut o eroare la comunicarea cu serverul.'});
    });
});




     
    }
  });

Turbo.setProgressBarDelay();


